<template>
    <el-row>
        <el-col>
            <div class="footer base-bg bbs-third-color">
                <div>
                    <a href="" class="info-link" target="_blank">做自己想做的人</a>
                </div>
                <div>
                    <a href="" class="info-link" target="_blank">生命不要求我们做最好的,只要求我们做最大的努力</a>
                </div>
            </div>
        </el-col>
    </el-row>
</template>

<script>
export default {
    name: "system-footer",
    data() {
        return {
            baseUrl: '/bbs/system/footer',
        }
    },
    created() {

    },
    methods: {}
}
</script>

<style lang="less" scoped>
.footer {
    border-radius: 5px;
    margin: 20px 10px;
    //background-color: #fff;
    padding: 30px 15px;
    >div:first-child{
        margin-bottom: 15px;
    }
    a{
        text-decoration: none;
        color: #545962;
        font-size: 14px;
        display: inline-block;
        padding-right: 30px;
    }
}
</style>
