<template>
    <div>
        <div class="input-item">
            <input placeholder="请输入手机号" type="text" v-model="form.username">
        </div>
        <div class="input-item code-input">
            <input placeholder="请输入验证码" type="text" v-model="form.password">
            <span class="code">
                <el-link size="mini" type="primary">获取验证码</el-link>
            </span>
        </div>
        <div class="btn-area">
            <el-button class="login-btn" @click="submit" type="primary">
                <span class="w">登</span> <span class="w">录</span>
            </el-button>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: {
                username: '',
                password: '',
            }
        }
    },
    methods: {
        submit: function () {

        }
    }
}

</script>
<style scoped lang="less">
.login-btn {
    //letter-spacing: 50px;
    display: block;
    width: 100%;
    padding: 12px 0;
    font-size: 18px;

    .w {
        padding: 0 25px;
    }
}

.input-item {
    padding: 30px 0;
    position: relative;

    &::after {
        content: ' ';
        display: block;
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #e3e2e2;
    }

    &.code-input {
        display: flex;

        &::after {
            bottom: 24px;
        }

        .code {
            width: 100px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

    }

    input {
        font-size: 16px;
        border: none;
        display: inline-block;
        width: 100%;
        padding: 5px 0;
        letter-spacing: 1px;
        border-radius: 4px;

        &:-webkit-autofill {
            box-shadow: 0 0 0 1000px white inset;
            -webkit-box-shadow: 0 0 0px 1000px white inset;
            -webkit-text-fill-color: #333;
        }

        &[type=password] {
            letter-spacing: 2px;

        }

        &::placeholder {
            font-size: 14px;
        }

        &:focus {
            outline: none;
        }
    }
}
</style>
