<template>
    <nav class="top-header base-bg">
        <div class="top-logo">
            <img class="logo-img" src="/static/bbs/images/login/logo_my.png" alt="">
            <img class="text-img" src="/static/bbs/images/index/logo_text.png" alt="">
        </div>
        <div class="left-nav">
            <ul>
                <li v-for="item in topMenu.nav" :key="item.nav_id">
                    <a :target="item.open===1?'_blank':''" :href="item.url">
                        {{ item.title }}
                    </a>
                </li>
            </ul>
        </div>
        <!--        <div v-if="search" class="top-search">-->
        <!--            <top-search></top-search>-->
        <!--        </div>-->
        <div v-if="user" class="right-area">
            <div class="notice">
                <notice></notice>
            </div>
            <div class="profile">
                <user-profile></user-profile>
            </div>
        </div>
    </nav>
</template>
<script>
import UserProfile from "@/components/common/userProfile.vue";
import TopSearch from "@/components/common/top-search.vue";
import Notice from "@/components/common/notice.vue";

export default {
    props: {
        search: {
            default: true,
        },
        user: {
            default: true,
        }
    },
    data() {
        return {
            topMenu: {
                nav: [
                    // {
                    //     title: "首页",
                    //     url: '/'
                    // },
                    {
                        title: "首页",
                        url: '/wenda/index'
                    },
                    // {
                    //     title: "Bigemap官网",
                    //     open: 1,
                    //     url: 'http://www.bigemap.com'
                    // },
                ],
                list: [],
                active: '',
            }
        }
    },
    components: {
        UserProfile, TopSearch, Notice
    },
    created() {
        this.getNav();
    },
    computed: {
        menu: function () {
            let menu = this.$store.state.permission.filter((v) => v.type === 'menu' && v.name === this.topMenu.active);
            return this.tools.loop(menu, 'menu_id');
        },
    },
    methods: {
        getNav() {
            // this.$bbs.get('/bbs/nav/index').then(data => {
            //     console.log(data.data.nav);
            //     this.topMenu.nav = data.data.nav;
            // });
        },
        handleCommand(command) {
            switch (command) {
                case 'out':
                    sessionStorage.clear();
                    this.$router.push('/login');
                    break;
            }
        },
    }
}
</script>
<style scoped lang="less">
.top-header {
    display: flex;
    height: 55px;
    //background-color: #fff;
    padding: 0 40px;
    box-shadow: 0 1px 3px 0 rgb(0 34 77 / 10%);

    .top-logo {
        display: flex;
        padding-right: 150px;
        align-items: center;

        .logo-img {
            width: 60px;
            display: inline-block;
        }
        .text-img{
            display: inline-block;
            padding-top: 4px;
            width: 55px;
        }
        .index-title {
            display: inline-block;
            padding-top: 5px;
            //font-family: myfont,serif;
            color: #333;
            font-weight: bold;
            font-size: 18px;
        }
    }

    .left-nav {
        min-width: 360px;
        flex: 1;

        ul {
            display: flex;
            height: 100%;
            align-items: center;

            li {
                padding: 0 20px;

                a {
                    color: #333333;
                    text-decoration: none;

                    &:hover {
                        color: #0082FC;
                    }

                    &.active {
                        color: #0082FC;
                    }
                }
            }
        }
    }

    .top-search {
        //flex: 1;
        flex-shrink: 0;
        width: 300px;
    }

    .right-area {
        width: 160px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}
</style>
