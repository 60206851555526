<template>
    <div class="header-notice">
        <div class="notice-icon">
            <el-popover
                v-if="sum"
                width="200"
                trigger="hover"
                @show="show"
            >
                <div class="notice-body">
                    <div v-for="item in this.notice.list" :key="item.name">
                        <div class="title">
                            <el-link @click="jump(item)" type="primary">
                                {{ item.title }} <span class="index">{{ $store.state.notice[item.name].length }}</span>
                            </el-link>
                        </div>
                    </div>
                </div>
                <el-badge slot="reference" :value="sum">
                    <img src="/static/bbs/images/menu/notice.svg" alt="">
                </el-badge>
            </el-popover>
            <el-badge v-else>
                <img src="/static/bbs/images/menu/notice.svg" alt="">
            </el-badge>
        </div>
    </div>
</template>

<script>
export default {
    name: "notice",
    data() {
        return {
            baseUrl: '/bbs/user/notice',
            notice: {
                list: [],
                mapping: {notice: '通知', answer: '待审核问答', reply: '待审核回复', comment: '待审核评论'},
            }
        }
    },
    computed: {
        sum: function () {
            let sum = 0;
            for (let k in this.notice.mapping) {
                if (Array.isArray(this.$store.state.notice[k])){
                    sum += this.$store.state.notice[k].length;
                }
            }
            return sum;
        },
    },
    mounted() {
        return;
        if (!this.$store.state.userData.username) return;
        this.$bbs.get(this.baseUrl).then(data => {
            let store = {};
            for (let k in data.data) {
                if (data.data[k] && Array.isArray(data.data[k]) && this.notice.mapping[k]) {
                    store[k] = [];
                    this.notice.list.push({
                        title: this.notice.mapping[k],
                        name: k,
                        sum: data.data[k].length > 99 ? '99⁺' : data.data[k]
                    });
                    store[k] = data.data[k];
                }
            }
            this.$store.commit('updateNotice', store);
            // console.log(this.notice.list);
        });
    },
    created() {

    },
    methods: {
        show: function () {
            // //如果没有数据，不需要操作
            // if (!this.sum) return;
            // //如果已经处理过了,直接返回
            // if (this.notice.done) return;
        },
        jump(item) {
            console.log(item);
            let path = '/user/verify/' + item.name;
            if (this.$route.path === path) return;
            this.$router.push({
                path,
            });
        }
    },
}
</script>

<style scoped lang="less">
.notice-body {
    .title {
        padding-bottom: 5px;
    }
}

.header-notice {
    height: 100%;
    width: 80px;

    .notice-icon {
        display: flex;
        height: 100%;
        align-items: center;
        cursor: pointer;
        //justify-content: space-around;
        //.badge-item{
        //    padding: 0 15px;
        //}
        img {
            display: inline-block;
            width: 25px;
            height: 25px;
        }
    }
}
</style>
