<template>
    <div>
        <el-dialog
            title=""
            @opened="opened"
            :visible.sync="show"
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="beforeClose"
            @closed="closeDialog"
            width="800px"
        >
            <div class="login-container">
                <div class="login-description">
                    <div class="logo-text bbs-second-color">
                        <p>应用新GIS</p>
                        <p>构建新未来</p>
                    </div>
                    <div class="image">
                        <img src="/static/bbs/images/login/future.png" alt="">
                    </div>
                </div>
                <div class="login-form">
                    <div class="login-type">
                        <div @click="changeLoginType(item.name)" class="item" v-for="item in loginType.list"
                             :class="{active:loginType.active===item.name}"
                             :key="item.name">
                            <span>{{ item.title }}</span>
                        </div>
                    </div>
                    <input-scan @loginChange="loginChange" ref="scan" v-if="loginType.active==='scan'"></input-scan>
                    <input-password  @loginChange="loginChange" v-else-if="loginType.active==='password'"></input-password>
                    <input-phone @loginChange="loginChange" v-else-if="loginType.active==='phone'"></input-phone>
<!--                    <div v-if="loginType.active!=='scan'" class="btn-area">-->
<!--                        -->
<!--                    </div>-->
                    <div class="footer text-center">
                        <p>
                            <el-checkbox v-model="loginType.agree"></el-checkbox>
                            已阅读并同意 <a href="">《用户协议》</a> 和
                            <a href="">《隐私政策》</a></p>
                        <p class="third-login">第三方登陆(开发中)</p>
                        <p>
                            <img class="login-img" src="/static/bbs/images/login/wx.png" alt="">
                        </p>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import InputScan from './input-scan.vue';
import InputPassword from './input-password.vue';
import InputPhone from './input-phone.vue';
import Cookie from "js-cookie";
import storage from "@/utils/storage";
import tools from "@/utils/tools";

export default {
    components: {
        InputScan, InputPassword, InputPhone
    },
    data() {
        return {
            show: false,
            loginType: {
                active: 'password',
                agree: true,
                list: [
                    {title: "扫码登录", name: 'scan'},
                    {title: "账号登录", name: 'password'},
                    {title: "验证码登录", name: 'phone'},
                ],
            }
        }
    },
    watch: {
        '$store.state.need_login': function (val) {
            this.show = !!val;
        }
    },
    mounted() {
        if (!this.$store.state.userData||!this.$store.state.userData.username){
            this.show=true;
        }
    },
    methods: {
        closeDialog() {
            this.show = false;
            //这里关闭之后，还需要停止可能存在的登录扫描的定时器
        },
        beforeClose(done) {
            this.$refs.scan && this.$refs.scan.stopScan();
            done();
        },
        opened(){
            this.$refs.scan && this.$refs.scan.getQrcodeUrl();
        },
        loginChange(loginInfo){
            //登录响应事件统一走这里
            let data=loginInfo.loginData;
            if (data.code !== 200) {
                this.$message.warning(data.msg || data.message || '登陆失败');
                return;
            }
            localStorage.removeItem(process.env.VUE_APP_ELSE_CACHE);
            if (!data.data.userData.face) data.data.userData.face='/static/bbs/images/answer/face.png';
            this.$store.dispatch('userData', data.data.userData);
            storage.token(data.data.token);
            //获取有可能存在的缓存路径
            // let jumpUrl = storage.storage('cache_url');
            // if (!jumpUrl) jumpUrl = '/wenda/index';
            // this.$router.push({
            //     path: jumpUrl,
            // });
            this.$message.success('登陆成功!');
            this.$store.commit('needLogin', '');
        },
        changeLoginType(type) {
            this.loginType.active = type;
        },

    }
}
</script>
<style scoped lang="less">
.login-container {
    display: flex;
    padding-bottom: 10px;
    //padding: 10px;

    .login-description {
        width: 50%;
        user-select: none;
        padding: 50px 40px 0;

        .logo-text {
            padding-left: 25px;

            p {
                font-size: 36px;
                padding: 10px 0;
            }
        }

        .image {
            img {
                max-width: 100%;
            }
        }
    }

    .login-form {
        width: 50%;

        .login-type {
            display: flex;
            padding: 20px 0;

            .item {
                width: 33%;
                font-size: 16px;
                flex: 1;
                position: relative;

                span {
                    position: relative;
                    display: inline-block;
                    cursor: pointer;
                    color: #333;

                    &::after {
                        transition: all ease-in-out 0.25s;
                        position: absolute;
                        width: 0;
                        content: ' ';
                        bottom: -5px;
                        display: block;
                        height: 3px;
                        background-color: #007bff;
                    }
                }

                &.active {
                    span {
                        &::after {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .footer {
            padding-top: 20px;
            font-size: 12px;

            p {
                padding: 5px 0;
            }

            .third-login {
                position: relative;

                &::before, &::after {
                    width: 34%;
                    height: 1px;
                    top: 10px;
                    border-radius: 2px;
                    background-color: #ccc;
                    content: ' ';
                    display: block;
                    position: absolute;
                }

                &::after {

                    right: 0;
                }
            }

            .login-img {
                width: 30px;
            }
        }
    }
}

</style>
